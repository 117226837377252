import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser } from '../../../redux/actions/Auth';
import React from 'react';
import axios from './config';
import { FETCH_DATA_SUCCESS } from '@jumbo/constants/ActionTypes';
import { history } from 'redux/store';

const JWTAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('auth/register', {
          email: email,
          password: password,
          name: name,
        })
        .then(({ data }) => {
          if (data.result) {
            localStorage.setItem('token', data.token.access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
            dispatch(fetchSuccess());
            dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogin: ({ email, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post('users/login', {
            email: email,
            password: password,
            user_type: '1,2',
          })
          .then(({ data }) => {
            // if (data.result) {
            console.log('data', data);
            localStorage.setItem('token', data.data.auth_tokens);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.data.auth_tokens;
            console.log('welcome msg');
            dispatch(fetchSuccess('Welcome to UnderWriter.'));
            dispatch(JWTAuth.getAuthUser(true, data.data.auth_tokens));
            // token.access_token
            // } else {
            //   dispatch(fetchError(data.error));
            // }
          })
          .catch(function(error) {
            setTimeout(() => {
              dispatch(fetchError(error?.response?.data?.message), 3000);
            }, 2000);
          });
      } catch (error) {
        dispatch(fetchError(error?.response?.data?.message), 3000);
      }
    };
  },

  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .delete('users/logout')
        .then(({ data }) => {
          console.log('check 1');
          console.log('data', data);
          if (data) {
            console.log('check 2');
            dispatch(fetchSuccess());
            localStorage.removeItem('token');
            // window.location.reload(true);
            dispatch(setAuthUser(null));
            history.push('/signin');
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            dispatch(setAuthUser(null));
          } else {
            dispatch(fetchError(error?.response?.data?.message));
          }
        });
      // .catch(function(error) {
      //   dispatch(fetchError(error.message));
      // });
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      if (!token) {
        const token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      // console.log("out token",token)
      if (localStorage.getItem('token')) {
        // dispatch(fetchStart());
        dispatch(updateLoadUser(loaded));
        // console.log("in token",token)
        axios('users/profile')
          // .post('auth/me')
          .then(({ data }) => {
            console.log('checkkkkk', data.data);
            if (data.data) {
              // dispatch(fetchSuccess());
              dispatch({ type: FETCH_DATA_SUCCESS });
              dispatch(setAuthUser(data.data));
            } else {
              dispatch(updateLoadUser(true));
            }
          })
          .catch(function(error) {
            dispatch(updateLoadUser(true));
          });
      } else {
        // if(!localStorage.getItem('token')){
        dispatch(setAuthUser(null));
        history.push('/signin');
        // }
        // history.push('/signin');
      }
    };
  },

  onForgotPassword: email => {
    return dispatch => {
      console.log('email : ', email);
      dispatch(fetchStart('Checking'));
      axios
        .post('users/forgot-password', {
          email: email.email,
          user_type: '1,2',
        })
        .then(({ data }) => {
          if (data) {
            setTimeout(() => {
              dispatch(setForgetPassMailSent(true));
              dispatch(fetchSuccess());
            }, 300);
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(error => {
          dispatch(fetchError(error?.response?.data?.message));
        });
    };
  },

  onChangePassword: (oldPassword, newPassword, confirmPassword) => {
    return dispatch => {
      dispatch(fetchStart('checking'));
      axios
        .put('users/change-password', {
          old_password: oldPassword,
          new_password: newPassword,
          confirm_password: confirmPassword,
        })
        .then(({ data }) => {
          if (data) {
            setTimeout(() => {
              dispatch(fetchSuccess('Changed password successfully.'));
            }, 300);
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            dispatch(setAuthUser(null));
            history.push('/signin');
          } else {
            dispatch(fetchError(error?.response?.data?.message));
          }
        });
    };
  },

  editProfile: (firstName, lastName) => {
    return dispatch => {
      dispatch(fetchStart(''));
      axios
        .put('users/edit-profile', {
          first_name: firstName,
          last_name: lastName,
        })
        .then(({ data }) => {
          if (data) {
            setTimeout(() => {
              dispatch(fetchSuccess('Profile updated successfully.'));
            }, 2000);
            dispatch(JWTAuth.getAuthUser(true));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            dispatch(setAuthUser(null));
            history.push('/signin');
          } else {
            dispatch(fetchError(error?.response?.data?.message));
          }
        });
    };
  },

  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
