import React from 'react';
// import { Typography, Box, Grid, RadioGroup, FormControl, FormControlLabel } from '@mui/material';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Radio from '@material-ui/core/Radio';
import clsx from 'clsx';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '& .MuiFormLabel-root': {
      color: '#909090',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.text.primary,
    },
    cardContentRoot: {
      padding: '0 !important',
    },

    scrollbarRoot: {
      height: 340,
    },
    badgeRoot: {
      color: theme.palette.common.white,
      borderRadius: 30,
      fontSize: 12,
      padding: '2px 10px',
      display: 'inline-block',
    },
    authThumb: {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '50%',
        order: 2,
      },
    },
    authContent: {
      padding: 30,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: props => (props.variant === 'default' ? '50%' : '100%'),
        order: 1,
      },
      [theme.breakpoints.up('xl')]: {
        padding: 50,
      },
    },
    titleVersion: {
      // marginBottom: 14,
      letterSpacing: 0.15,
      marginRight: 24,
      color: theme.palette.text.primary,
    },
    headerTitle: {
      fontSize: 20,
      fontStyle: 'bold',
    },
    textFieldRoot: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: alpha(theme.palette.common.dark, 0.12),
      },
    },
    formcontrolLabelRoot: {
      '& .MuiFormControlLabel-label': {
        [theme.breakpoints.down('xs')]: {
          fontSize: 12,
        },
      },
    },
    noRecord: {
      textAlign: 'center',
      height: '250px',
      fontSize: 16,
      color: theme.palette.text.disabled,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.up('lg')]: {
        fontSize: 20,
      },
      border: 'none',
    },
    tableCellRoot: {
      textAlign: 'center',
    },
  },
  tableHead: {
    '& .MuiTableCell-head': {
      fontWeight: 600,
    },
  },
  tableRowRoot: {
    '& .MuiTableCell-root': {
      fontWeight: 300,
    },
  },
  disabledText1: {
    fontSize: 16,
    fontStyle: 'italic',
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('lg')]: {
      fontSize: 12,
    },
  },
  disabledText2: {
    marginLeft: '10px',
    fontSize: 16,
    fontStyle: 'italic',
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('lg')]: {
      fontSize: 12,
    },
  },
}));

function StyledRadio({ selected, ...props }) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const QuestionAccordion = ({
  question,
  index,
  setFieldValue,
  options,
  correctAnswer,
  correctOption,
  setSelectedCorrectOption,
}) => {
  const classes = useStyles();
  const { id } = useParams();
  console.log('question', question);

  return (
    <Accordion style={{ marginBottom: '15px' }} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography style={{ padding: '4px 16px', marginBottom: '0px', fontSize: '16px', fontWeight: '600' }}>
          {`Question ${index + 1}`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box p={4} pb={0} pt={0} style={{ width: '100%' }}>
          <Box alignItems="center" mb={6}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Box alignItems="center">
                  <AppTextInput
                    id={`questions.${index}.text`}
                    label="Question"
                    placeholder="Question"
                    size="medium"
                    fullWidth
                    margin="0"
                    variant="outlined"
                    value={id ? question.question : question.text}
                    onChange={e =>
                      id
                        ? setFieldValue(`questions.${index}.question`, e.target.value || question.question)
                        : setFieldValue(`questions.${index}.text`, e.target.value)
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <RadioGroup
                  aria-label="correct answer"
                  name={`questions.${index}.correctAnswer`}
                  value={id ? correctOption : ''}
                  // value={correctOption || ''}
                  onChange={event => {
                    setSelectedCorrectOption(event.target.value);
                    setFieldValue(`questions.${index}.correctOption`, event.target.value);
                  }}>
                  {!id && (
                    <>
                      {question.options.map((option, optionIndex) => (
                        <Grid item xs={12} key={optionIndex}>
                          <Box
                            ml={0}
                            mr={0}
                            mt={5}
                            style={{ gap: '20px' }}
                            alignItems="center"
                            justifyContent="space-between"
                            display="flex">
                            <AppTextInput
                              id={`questions.${index}.options.${optionIndex}.text`}
                              label={`Option ${optionIndex + 1}`}
                              size="medium"
                              placeholder={`Option ${optionIndex + 1}`}
                              fullWidth
                              margin="0"
                              variant="outlined"
                              value={option.text}
                              onChange={e => setFieldValue(`questions.${index}.options.${optionIndex}.text`, e.target.value)} // Add state handling
                            />
                            <FormControl component="fieldset">
                              <FormControlLabel
                                value={option.text}
                                control={<StyledRadio />}
                                label="Correct"
                                checked={option.isCorrect}
                                onChange={() => {
                                  setFieldValue(
                                    `questions.${index}.options`,
                                    question.options.map((opt, idx) => ({ ...opt, isCorrect: idx === optionIndex })),
                                  );
                                  setFieldValue(`questions.${index}.correctOption`, option.text);
                                }}
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                      ))}
                    </>
                  )}
                  {id && (
                    <>
                      {options.map((option, optionIndex) => (
                        <Grid item xs={12} key={optionIndex}>
                          <Box
                            ml={0}
                            mr={0}
                            mt={5}
                            style={{ gap: '20px' }}
                            alignItems="center"
                            justifyContent="space-between"
                            display="flex">
                            <AppTextInput
                              id={`questions.${index}.options.${optionIndex}.text`}
                              label={`Option ${optionIndex + 1}`}
                              size="medium"
                              placeholder={`Option ${optionIndex + 1}`}
                              fullWidth
                              margin="0"
                              variant="outlined"
                              value={option}
                              onChange={e =>
                                setFieldValue(`questions.${index}.options.${optionIndex}`, e.target.value || option)
                              }
                            />

                            <FormControl component="fieldset">
                              <FormControlLabel
                                value={id ? option : option.text}
                                control={<StyledRadio />}
                                label="Correct"
                                checked={correctOption === option}
                                onChange={e =>
                                  setFieldValue(
                                    `questions.${index}.correctOption`,
                                    e.target.value, // Set correct option when selected
                                  )
                                }
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                      ))}
                    </>
                  )}
                </RadioGroup>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default QuestionAccordion;
