import axios2 from '../../services/auth/jwt/configfinhub';
import axios from '../../services/auth/jwt/config';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import {
  GET_COMPANIES,
  GET_COMPANIES_DETAILS,
  GET_COMPANIES_PROFILE,
  ADD_COMPANIES,
  GET_COMPANIESFORTABLE,
  GET_COMPANIESFORMATCHWITHTABLE,
} from '../../@jumbo/constants/ActionTypes';
import {
  companiesList,
  companiesProfile,
  addCompanies,
  companiesListForTable,
  companiesStatusChange,
  companiesMakeDefault,
} from '../../@jumbo/constants/ApiConstant';
import { history } from 'redux/store';
import { setAuthUser } from 'redux/actions/Auth';
import axiosThrottle from 'axios-request-throttle';

// get companies list from search
export const fetchCompanies = (searchValue, callbackFun) => {
  return dispatch => {
    // dispatch(fetchStart());
    axios
      .get(companiesList, {
        params: { search: searchValue },
        // params: { function:'SYMBOL_SEARCH', keywords:searchValue, apikey:'CLNQ9Z1CRPPWF7K2' },
      })
      .then(data => {
        console.log('data.data', data.data);
        if (data.status === 200) {
          dispatch(fetchSuccess());
          // dispatch({type:FETCH_DATA_SUCCESS})
          dispatch({ type: GET_COMPANIES, payload: data.data.data.company_list });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Companies list not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_COMPANIES, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current companies for view tab
export const setCurrentCompanies = user => {
  return dispatch => {
    dispatch({ type: GET_COMPANIES_DETAILS, payload: user });
  };
};

axiosThrottle.use(axios2, {
  requestsPerSecond: 0,
});
// get companies profile from ADD button
export const fetchCompaniesProfile = (searchValue, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios2
      .get(companiesProfile, {
        params: { symbol: searchValue, token: 'cf3m19qad3i4fg34uun0cf3m19qad3i4fg34uung' },
      })
      .then(data => {
        console.log('data', data);
        if (data.status === 200) {
          dispatch(fetchSuccess());
          // dispatch({type:FETCH_DATA_SUCCESS})
          dispatch({ type: GET_COMPANIES_PROFILE, payload: data.data.result });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Sub admin list not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_COMPANIES_PROFILE, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// add company from search list
export const addCompaniesToList = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addCompanies, user)
      .then(data => {
        if (data) {
          console.log('data', data.data);
          setTimeout(() => {
            dispatch(fetchSuccess('New Company added successfully.'));
          }, 2000);
          if (callbackFun) callbackFun(data.data.data.ticker);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

// get companies list in Table
export const getCompaniesList = (sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());

    axios
      .get(companiesListForTable, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          // dispatch({type:FETCH_DATA_SUCCESS})
          dispatch({ type: GET_COMPANIESFORTABLE, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Companies list not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_COMPANIESFORTABLE, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
          if (callbackFun) callbackFun();
        }
      });
  };
};

export const updateCompaniesStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(companiesStatusChange, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess(response?.data?.message));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const updateCompaniesMakeDefault = (data, list) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(companiesMakeDefault, data)
      .then(response => {
        if (response) {
          // console.log("response",response)
          dispatch({ type: GET_COMPANIESFORTABLE, payload: list });
          setTimeout(() => {
            dispatch(fetchSuccess(response?.data?.message));
            // dispatch(fetchSuccess('Make default updated successfully.'));
          }, 2000);
          // if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// get companies list FROM Table for match in Add Companies dialogue
export const getCompaniesListForMatchInAddCompanies = (
  sortBy,
  sort,
  searchTerm,
  page,
  limit,
  filterOptions,
  callbackFun,
) => {
  return dispatch => {
    // dispatch(fetchStart());

    axios
      .get(companiesListForTable, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        if (data.status === 200) {
          // console.log("data",data)
          dispatch(fetchSuccess());
          // dispatch({type:FETCH_DATA_SUCCESS})
          dispatch({ type: GET_COMPANIESFORMATCHWITHTABLE, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Companies list not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_COMPANIESFORMATCHWITHTABLE, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// get companies list in Table
export const getCompaniesListForViewDialogue = (sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
    // dispatch(fetchStart());

    axios
      .get(companiesListForTable, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        console.log('data', data);
        if (data.status === 200) {
          dispatch(fetchSuccess());
          // dispatch({type:FETCH_DATA_SUCCESS})
          dispatch({ type: GET_COMPANIESFORTABLE, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Companies list not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_COMPANIESFORTABLE, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
