import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import GridContainer from '../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import CmtCard from '../../../@coremat/CmtCard';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field, FieldArray, ErrorMessage, useFormikContext } from 'formik';
import './AcademyCMSPreview.css';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import ContentPage from '../../../@jumbo/components/Common/ContentPage/index';
import { baseURL } from 'services/auth/jwt/config';

const variant = 'default';

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none',
  },
  backButton: {
    marginBottom: '16px',
  },
}));

const contentPagesData = [
  {
    header: 'Lesson 1: Introduction Lorem Ipsum',
    subHeader: 'Getting Started Lorem Ipsum is simply dummy text',
    imageURL: 'https://i.imghippo.com/files/VRben1728449247.jpg',
    paragraph:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    subParagraph:
      'It is a long established fact that a reader will be Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using  Content here, content here, making it look like readable English. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
  },
  {
    header: 'Lesson 2: Introduction',
    subHeader: 'Getting Started',
    imageURL: 'https://i.imghippo.com/files/VRben1728449247.jpg',
    paragraph:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    subParagraph:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using  Content here, content here, making it look like readable English.',
  },
  {
    header: 'Lesson 3: Introduction',
    subHeader: 'Getting Started',
    imageURL: 'https://i.imghippo.com/files/VRben1728449247.jpg',
    paragraph:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500',
    subParagraph:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its ',
  },
  {
    header: 'Lesson 4: Introduction',
    subHeader: 'Getting Started',
    imageURL: 'https://i.imghippo.com/files/VRben1728449247.jpg',
    paragraph:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    subParagraph:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using  Content here, content here, making it look like readable English.',
  },
  {
    header: 'Lesson 5: Introduction',
    subHeader: 'Getting Started',
    imageURL: 'https://i.imghippo.com/files/VRben1728449247.jpg',
    paragraph:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    subParagraph:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using  Content here, content here, making it look like readable English.',
  },
  {
    header: 'Lesson 6: Introduction',
    subHeader: 'Getting Started',
    imageURL: 'https://i.imghippo.com/files/VRben1728449247.jpg',
    paragraph:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    subParagraph:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using  Content here, content here, making it look like readable English.',
  },
];

const LessonPreviews = () => {
  const classes = useStyles({ variant });
  const { id } = useParams();
  const history = useHistory();
  const { currentUser } = useSelector(({ ACADEMYCMS }) => ACADEMYCMS);
  console.log('currentUser', currentUser);

  const breadcrumbs = [
    { label: 'Home', link: '/' },
    { label: 'Lesson Previews', isActive: true },
  ];

  const handleBackClick = () => {
    history.goBack();
  };

  return (
    <>
      <PageContainer heading="Lesson Previews" breadcrumbs={breadcrumbs}>
        <Button className={classes.backButton} variant="outlined" onClick={handleBackClick}>
          Back
        </Button>
        <Formik
          enableReinitialize={true}
          initialValues={{
            contentPagesData: (currentUser?.lessonContent && currentUser?.lessonContent) || [],
            // contentPagesData: contentPagesData || [],
          }}
          // setSubmitting(false)
        >
          {({ errors, isSubmitting, values, setFieldValue }) => {
            console.log('values Form', values);
            return (
              <Form>
                <Grid container spacing={5}>
                  {values?.contentPagesData &&
                    values?.contentPagesData?.map((page, index) => (
                      <Grid item xs={12} sm={4} xl={3} key={index}>
                        <Box alignItems="center">
                          <Typography variant="h4" style={{ marginBottom: '10px' }}>
                            {`Content page ${index + 1}`}
                          </Typography>
                          <ContentPage
                            imageURL={page.imageURL ? page.imageURL : `${process.env.PUBLIC_URL}/images/app-logo.png`}
                            header={page.header}
                            subHeader={page.subHeader}
                            paragraph={page.paragraph}
                            subParagraph={page.subParagraph}
                          />
                          <ErrorMessage name="lessonTitle" component="div" className="error" />
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </PageContainer>
    </>
  );
};

export default LessonPreviews;
