import React, { useState } from 'react';
import { TextareaAutosize, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import AppTextInput from '../../../../@jumbo/components/Common/formElements/AppTextInput';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  relative: {
    position: 'relative',
  },
  input: {
    display: 'none',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '& .MuiFormLabel-root': {
      color: '#909090',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.text.primary,
    },
    cardContentRoot: {
      padding: '0 !important',
    },

    scrollbarRoot: {
      height: 340,
    },
    badgeRoot: {
      color: theme.palette.common.white,
      borderRadius: 30,
      fontSize: 12,
      padding: '2px 10px',
      display: 'inline-block',
    },
    authThumb: {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '50%',
        order: 2,
      },
    },
    authContent: {
      padding: 30,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: props => (props.variant === 'default' ? '50%' : '100%'),
        order: 1,
      },
      [theme.breakpoints.up('xl')]: {
        padding: 50,
      },
    },
    titleVersion: {
      // marginBottom: 14,
      letterSpacing: 0.15,
      marginRight: 24,
      color: theme.palette.text.primary,
    },
    headerTitle: {
      fontSize: 20,
      fontStyle: 'bold',
    },
    textFieldRoot: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: alpha(theme.palette.common.dark, 0.12),
      },
    },
    formcontrolLabelRoot: {
      '& .MuiFormControlLabel-label': {
        [theme.breakpoints.down('xs')]: {
          fontSize: 12,
        },
      },
    },
    noRecord: {
      textAlign: 'center',
      height: '250px',
      fontSize: 16,
      color: theme.palette.text.disabled,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.up('lg')]: {
        fontSize: 20,
      },
      border: 'none',
    },
    tableCellRoot: {
      textAlign: 'center',
    },
  },
  tableHead: {
    '& .MuiTableCell-head': {
      fontWeight: 600,
    },
  },
  tableRowRoot: {
    '& .MuiTableCell-root': {
      fontWeight: 300,
    },
  },
  disabledText1: {
    fontSize: 16,
    fontStyle: 'italic',
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('lg')]: {
      fontSize: 12,
    },
  },
  disabledText2: {
    marginLeft: '10px',
    fontSize: 16,
    fontStyle: 'italic',
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('lg')]: {
      fontSize: 12,
    },
  },
}));

const LessonAccordion = ({
  lessonNumber,
  headerName,
  subHeaderName,
  paragraphName,
  subParagraphName,
  imageName,
  setFieldValue,
  lessonContent,
}) => {
  const classes = useStyles();
  const { id } = useParams();
  const [previewImageForLessonPage, setPreviewImageForLessonPage] = useState(lessonContent?.lessonImage);
  console.log('lessonContent', lessonContent);

  return (
    <Accordion style={{ marginBottom: '15px' }} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          variant="h6"
          style={{
            padding: '4px 16px',
            marginBottom: '0px',
            fontSize: '16px',
            fontWeight: '600',
          }}>
          Content Page {lessonNumber}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Box p={4} pb={0} pt={0} style={{ width: '100%' }}>
          {!id && (
            <>
              <Box alignItems="center" mb={6}>
                <Box alignItems="center" mb={5}>
                  <div className={classes.root}>
                    <input
                      accept="image/*"
                      className={classes.input}
                      id={`upload-image-${lessonNumber}`}
                      type="file"
                      onChange={e => {
                        const files = e.currentTarget.files;
                        if (files && files.length > 0) {
                          setFieldValue(imageName, files[0]);
                          const previewUrl = URL.createObjectURL(files[0]);
                          setPreviewImageForLessonPage(previewUrl);
                        }
                      }}
                    />

                    <label htmlFor={`upload-image-${lessonNumber}`}>
                      <Button variant="contained" color="primary" component="span">
                        <IconButton style={{ color: 'white' }} aria-label="upload picture" component="span">
                          <PhotoCamera />
                        </IconButton>{' '}
                        Content page image
                      </Button>
                    </label>
                    {/* <label htmlFor="lessonImage"></label> */}
                    {previewImageForLessonPage && (
                      <div>
                        <div
                          className="relative border-1 rounded-lg overflow-hidden"
                          style={{
                            position: 'relative',
                            display: 'inline-flex',
                            width: 'auto',
                            borderRadius: '8px',
                            overflow: 'hidden',
                          }}>
                          <img
                            src={previewImageForLessonPage}
                            alt="preview-lesson-image"
                            className="preview-img"
                            style={{
                              objectFit: 'cover',
                              height: 'auto',
                              width: '100%',
                              maxWidth: '250px',
                            }}
                          />
                          <button
                            type="button"
                            className="absolute top-0 right-0 mt-[-10px] mr-[-10px] w-[24px] h-[24px] bg-white border border-gray-200 rounded-full flex items-center justify-center p-1"
                            style={{
                              marginTop: '5px',
                              marginRight: '5px',
                              position: 'absolute',
                              top: '5px',
                              right: '5px',
                              background: '#ffffff',
                              borderRadius: '9999px',
                              padding: '2px',
                              zIndex: '2',
                              width: '24px',
                              height: '24px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              // const updatedImages = values?.images.filter((img, imgIndex) => imgIndex !== index);

                              setFieldValue(imageName, null);
                              setPreviewImageForLessonPage(null);
                              // setImageKey(Date.now());
                            }}>
                            <img
                              src={`${process.env.PUBLIC_URL}/images/icons/close.svg`}
                              className="text-gray-500 w-4 h-4"
                            />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Box>

                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Box alignItems="center">
                      <AppTextInput
                        id={`header-${lessonNumber}`}
                        size="medium"
                        label="Header"
                        placeholder="Lesson header"
                        fullWidth
                        margin="0"
                        variant="outlined"
                        onChange={e => setFieldValue(headerName, e.target.value)}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box alignItems="center">
                      <AppTextInput
                        id={`subheader-${lessonNumber}`}
                        size="medium"
                        label="Sub header"
                        placeholder="Lesson Sub header"
                        fullWidth
                        margin="0"
                        variant="outlined"
                        onChange={e => setFieldValue(subHeaderName, e.target.value)}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box alignItems="center">
                      <TextareaAutosize
                        className="textAreaControl"
                        aria-label="minimum height"
                        minRows={5}
                        style={{
                          width: '100%',
                          padding: '14px',
                          borderRadius: '4px',
                          borderColor: 'rgba(0, 0, 0, 0.23)',
                          fontSize: '14px',
                          color: 'rgba(0, 0, 0, 0.87)',
                        }}
                        placeholder="Paragraph"
                        onChange={e => setFieldValue(paragraphName, e.target.value)}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box alignItems="center">
                      <TextareaAutosize
                        className="textAreaControl"
                        aria-label="minimum height"
                        minRows={5}
                        style={{
                          width: '100%',
                          padding: '14px',
                          borderRadius: '4px',
                          borderColor: 'rgba(0, 0, 0, 0.23)',
                          fontSize: '14px',
                          color: 'rgba(0, 0, 0, 0.87)',
                        }}
                        placeholder="Sub paragraph"
                        onChange={e => setFieldValue(subParagraphName, e.target.value)}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
          {id && (
            <>
              {console.log('previewImageForLessonPage', previewImageForLessonPage)}
              <Box alignItems="center" mb={6}>
                <Box alignItems="center" mb={5}>
                  <div className={classes.root}>
                    <input
                      accept="image/*"
                      className={classes.input}
                      id={`upload-image-${lessonNumber}`}
                      type="file"
                      onChange={e => {
                        const files = e.currentTarget.files;
                        if (files && files.length > 0) {
                          setFieldValue(imageName, files[0]);
                          const previewUrl = URL.createObjectURL(files[0]);
                          setPreviewImageForLessonPage(previewUrl);
                        }
                      }}
                    />

                    <label htmlFor={`upload-image-${lessonNumber}`}>
                      <Button variant="contained" color="primary" component="span">
                        <IconButton style={{ color: 'white' }} aria-label="upload picture" component="span">
                          <PhotoCamera />
                        </IconButton>{' '}
                        Contetnt page image
                      </Button>
                    </label>
                    {/* <label htmlFor="lessonImage"></label> */}
                    {previewImageForLessonPage && (
                      <div>
                        <div
                          className="relative border-1 rounded-lg overflow-hidden"
                          style={{
                            position: 'relative',
                            display: 'inline-flex',
                            width: 'auto',
                            borderRadius: '8px',
                            overflow: 'hidden',
                          }}>
                          <img
                            src={previewImageForLessonPage}
                            alt="preview-lesson-image"
                            className="preview-img"
                            style={{
                              objectFit: 'cover',
                              height: 'auto',
                              width: '100%',
                              maxWidth: '250px',
                            }}
                          />
                          <button
                            type="button"
                            className="absolute top-0 right-0 mt-[-10px] mr-[-10px] w-[24px] h-[24px] bg-white border border-gray-200 rounded-full flex items-center justify-center p-1"
                            style={{
                              marginTop: '5px',
                              marginRight: '5px',
                              position: 'absolute',
                              top: '5px',
                              right: '5px',
                              background: '#ffffff',
                              borderRadius: '9999px',
                              padding: '2px',
                              zIndex: '2',
                              width: '24px',
                              height: '24px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              // const updatedImages = values?.images.filter((img, imgIndex) => imgIndex !== index);

                              setFieldValue(imageName, null);
                              setPreviewImageForLessonPage(null);
                              // setImageKey(Date.now());
                            }}>
                            <img
                              src={`${process.env.PUBLIC_URL}/images/icons/close.svg`}
                              className="text-gray-500 w-4 h-4"
                            />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Box>

                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Box alignItems="center">
                      <AppTextInput
                        id={`header-${lessonNumber}`}
                        size="medium"
                        label="Header"
                        placeholder="Lesson header"
                        fullWidth
                        margin="0"
                        variant="outlined"
                        value={lessonContent?.header}
                        onChange={e => setFieldValue(headerName, e.target.value)}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box alignItems="center">
                      <AppTextInput
                        id={`subheader-${lessonNumber}`}
                        size="medium"
                        label="Sub header"
                        placeholder="Lesson Sub header"
                        fullWidth
                        margin="0"
                        variant="outlined"
                        value={lessonContent?.subHeader}
                        onChange={e => setFieldValue(subHeaderName, e.target.value)}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box alignItems="center">
                      <TextareaAutosize
                        className="textAreaControl"
                        aria-label="minimum height"
                        minRows={5}
                        style={{
                          width: '100%',
                          padding: '14px',
                          borderRadius: '4px',
                          borderColor: 'rgba(0, 0, 0, 0.23)',
                          fontSize: '14px',
                          color: 'rgba(0, 0, 0, 0.87)',
                        }}
                        placeholder="Paragraph"
                        value={lessonContent?.paragraph}
                        onChange={e => setFieldValue(paragraphName, e.target.value)}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box alignItems="center">
                      <TextareaAutosize
                        className="textAreaControl"
                        aria-label="minimum height"
                        minRows={5}
                        style={{
                          width: '100%',
                          padding: '14px',
                          borderRadius: '4px',
                          borderColor: 'rgba(0, 0, 0, 0.23)',
                          fontSize: '14px',
                          color: 'rgba(0, 0, 0, 0.87)',
                        }}
                        placeholder="Sub paragraph"
                        value={lessonContent?.subParagraph}
                        onChange={e => setFieldValue(subParagraphName, e.target.value)}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default LessonAccordion;
