import React from 'react';
import { Grid, Typography, Paper, Box } from '@material-ui/core';
import { baseURL } from 'services/auth/jwt/config';

const ContentPage = ({ header, subHeader, imageURL, paragraph, subParagraph }) => {
  const defaultImageURL = `${process.env.PUBLIC_URL}/images/app-logo.png`;
  return (
    <Paper
      className="card-bg-grad"
      style={{ padding: '16px', marginBottom: '10px', textAlign: 'left', borderRadius: '16px' }}>
      <img
        src={imageURL || defaultImageURL}
        alt="Content Image"
        style={{
          width: '100%',
          height: 'auto',
          marginTop: '0px',
          borderRadius: '20px',
          height: '180px',
          objectFit: 'content',
        }}
      />
      <div
        className="mostly-customized-scrollbar"
        style={{ marginTop: '16px', maxHeight: '340px', minHeight: '340px', overflow: 'auto' }}>
        <Typography variant="h2" style={{ marginBottom: '5px', fontSize: '28px', fontWeight: '500px', color: '#1C1B1F' }}>
          {header}
        </Typography>
        <Typography variant="subtitle1" style={{ fontSize: '20px', fontWeight: '600px', color: '#1C1B1F' }}>
          {subHeader}
        </Typography>
        <div style={{ marginTop: '16px' }}>
          <Typography
            variant="body2"
            style={{ marginBottom: '10px', fontSize: '18px', fontWeight: '400px', color: '#1C1B1F' }}
            className="baskerville">
            {paragraph}
          </Typography>
          <Typography
            variant="body2"
            style={{ marginBottom: '10px', fontStyle: 'italic', fontSize: '14px', fontWeight: '400px', color: '#1C1B1F' }}
            className="baskerville">
            {subParagraph}
          </Typography>
        </div>
      </div>
    </Paper>
  );
};

export default ContentPage;
