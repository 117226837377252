import axios, { baseURL } from '../../services/auth/jwt/config';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { GET_ACADEMY_MODULES, GET_LESSON, GET_LESSON_DETAILS } from '../../@jumbo/constants/ActionTypes';
import {
  academyModuleList,
  addLesson,
  lessonDetails,
  lessonList,
  lessonStatusChange,
  updateLesson,
} from '../../@jumbo/constants/ApiConstant';
import { history } from 'redux/store';
import { setAuthUser } from 'redux/actions/Auth';

// get lesson list
export const getLessonList = (sortBy, sort, searchTerm, page, limit, filterOptions, subAdmin, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());

    axios
      .get(lessonList, {
        params: {
          sortOrder: `${sort}`,
          search: searchTerm,
          page: page + 1,
          limit: limit,
          status: filterOptions[0],
        },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          console.log('Data', data.data.data);

          // dispatch({type:FETCH_DATA_SUCCESS})
          dispatch({ type: GET_LESSON, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Lesson list not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_LESSON, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const setCurrentLesson = user => {
  return dispatch => {
    dispatch({ type: GET_LESSON_DETAILS, payload: user });
    console.log('called or not?????????????????');
  };
};

export const getLessonDetails = (userId, callbackFun) => {
  return async dispatch => {
    dispatch(fetchStart());

    try {
      const response = await fetch(`${baseURL}${lessonDetails}${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log('data lesson details', data.data);

        dispatch(fetchSuccess());
        dispatch({ type: GET_LESSON_DETAILS, payload: data.data });

        if (callbackFun) callbackFun();
      } else {
        console.log('jt calll this');
        dispatch(fetchError('There was something issue in responding server.'));
      }
    } catch (error) {
      console.log('jt calll this 2');
      if (error?.message === 'Lesson list not found.') {
        dispatch(fetchError(error.message));
        dispatch({ type: GET_LESSON_DETAILS, payload: [] });
      } else {
        dispatch(fetchError(error.message || 'There was an error processing the request.'));
      }
    }
  };
};

// export const getLessonDetails = (userId, callbackFun) => {
//   return dispatch => {
//     dispatch(fetchStart());
//     axios
//       .get(lessonDetails + userId)
//       .then(data => {
//         console.log('data lesson details', data.data.data);

//         if (data.status === 200 || data.status === 201) {
//           console.log('datdatddat', data);

//           dispatch(fetchSuccess());
//           dispatch({ type: GET_LESSON_DETAILS, payload: data.data.data });

//           if (callbackFun) callbackFun();
//         } else {
//           console.log('jt calll this');

//           dispatch(fetchError('There was something issue in responding server.'));
//         }
//       })
//       .catch(error => {
//         console.log('jt calll this 2');
//         if (error?.response?.data?.message === 'Lesson list not found.') {
//           dispatch(fetchError(error?.response?.data?.message));
//           dispatch({ type: GET_LESSON_DETAILS, payload: [] });
//         } else {
//           dispatch(fetchError(error?.response?.data?.message));
//         }
//       });
//   };
// };

export const changeLessonStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(lessonStatusChange, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Lesson status updated successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// add new Lesson
export const createNewLesson = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addLesson, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New lesson created successfully.'));
          }, 2000);
          history.push('/academy-cms');
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const getAcademyModuleList = callbackFun => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(academyModuleList)
      .then(data => {
        if (data.status === 200) {
          console.log('data academy module list', data);
          dispatch(fetchSuccess());
          dispatch({ type: GET_ACADEMY_MODULES, payload: data.data.data });
          if (callbackFun) callbackFun(data.data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Academy module list not found.'));
      });
  };
};

// update Lesson
export const updateAcademyLesson = (user, id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateLesson + id, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Lesson updated successfully.'));
          }, 2000);
          history.push('/academy-cms');
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};
