// setting
export const virsionList = '/version/list';
export const pushVirsionList = '/version/create';
export const virsionDelete = '/version/delete/';

//User Management >> End Users
export const endUsersList = '/admin/user-list';
export const endUserStatusChange = '/admin/status-change';
export const endUsersDetails = '/admin/details/';
export const endUsersExport = '/admin/export-end-users';
export const endUsersAllowAccess = '/admin/user-allow-subscription';

//User Management >> Sub Admins
export const addSubAdmin = '/admin/add-subadmin';
export const subAdminUsersList = '/admin/user-list';
export const subAdminStatusChange = '/admin/status-change';
export const subAdminDetails = '/admin/details/';

//Companies
// export const companiesList = '/query';
export const companiesList = '/admin/company/search';
export const companiesProfile = '/stock/profile2';
export const addCompanies = '/admin/company/add-company';
export const companiesListForTable = '/admin/company/get-list';
export const companiesStatusChange = '/admin/company/status-change';
export const companiesMakeDefault = '/admin/company/make-default-followed';

// Academy CMS
export const addLesson = '/academy/create-lesson-admin';
export const updateLesson = '/academy/update-lesson-admin/';
export const lessonList = '/academy/list-lessons';
export const lessonDetails = '/academy/get-lesson/';
export const lessonStatusChange = '/academy/status-change';
export const academyModuleList = '/academy/list-academy-modules';
