// import React from 'react';
// import IntlMessages from '../../../@jumbo/utils/IntlMessages';
// import Box from '@material-ui/core/Box';
// import { Button, IconButton, InputBase } from '@material-ui/core';
// import SearchIcon from '@material-ui/icons/Search';
// import { alpha, makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles(theme => ({
//   root: {
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: '100%',
//   },
//   errorNumber: {
//     color: theme.palette.primary.main,
//     fontWeight: 800,
//     lineHeight: 1,
//     marginBottom: 30,
//     textShadow: '10px 6px 8px hsla(0,0%,45.9%,.4)',
//   },
//   searchRoot: {
//     position: 'relative',
//     width: 260,
//     [theme.breakpoints.up('md')]: {
//       width: 350,
//     },
//     '& .searchBtn': {
//       position: 'absolute',
//       right: 0,
//       top: '50%',
//       transform: 'translateY(-50%)',
//       zIndex: 1,
//     },
//     '& .MuiInputBase-root': {
//       width: '100%',
//     },
//     '& .MuiInputBase-input': {
//       height: 48,
//       borderRadius: 4,
//       position: 'relative',
//       backgroundColor: theme.palette.background.paper,
//       border: '1px solid #ced4da',
//       fontSize: 16,
//       boxSizing: 'border-box',
//       padding: '5px 50px 5px 15px',
//       transition: theme.transitions.create(['border-color', 'box-shadow']),
//       '&:focus': {
//         boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
//         borderColor: theme.palette.primary.main,
//       },
//     },
//   },
// }));

// const AcademyCMS = () => {
//   const classes = useStyles();

//   return (
//     <Box className={classes.root}>
//       <Box fontSize={{ xs: 100, sm: 160 }} className={classes.errorNumber}>
//         Academy CMS
//       </Box>
//     </Box>
//   );
// };

// export default AcademyCMS;

import React, { useEffect, useState } from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import UserListRow from './UserListRow';
import UserTableHead from './UserTableHead';
import UserTableToolbar from './UserTableToolbar';
import { useDispatch, useSelector } from 'react-redux';
import AddEditUser from './AddEditUser';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { useDebounce } from '@jumbo/utils/commonHelper';
import useStyles from './index.style';
import UserDetailView from './UserDetailView';
import NoRecordFound from './NoRecordFound';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { getSubAdminList, getSubAdminDetails, setCurrentSubAdminManagement } from 'redux/actions/SubAdminManagement';
import { getLessonDetails, getLessonList, setCurrentLesson } from 'redux/actions/AcademyCMS';
import { GET_SUBADMIN, FETCH_DATA_SUCCESS, GET_SUBADMIN_DETAILS, GET_LESSON } from '@jumbo/constants/ActionTypes';
import { useHistory } from 'react-router-dom';

const AcademyCMSModule = () => {
  const classes = useStyles();
  const history = useHistory();
  const { users } = useSelector(({ ACADEMYCMS }) => ACADEMYCMS);
  const [orderBy, setOrderBy] = React.useState('created_at');
  const [order, setOrder] = React.useState('desc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: '' });
  const [usersFetched, setUsersFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedData, setSearchedData] = useState('debouncedSearchPagevalidation');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const dispatch = useDispatch();

  const breadcrumbs = [
    { label: 'Home', link: '/' },
    { label: 'Academy CMS', isActive: true },
  ];

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm !== searchedData) {
      dispatch(
        getLessonList(orderBy, order, debouncedSearchTerm, 0, rowsPerPage, filterOptions, () => {
          setFilterApplied(!!debouncedSearchTerm);
          setUsersFetched(true);
        }),
      );
      setSearchedData(debouncedSearchTerm);
      setPage(0);
    } else {
      dispatch(
        getLessonList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, () => {
          setFilterApplied(!!debouncedSearchTerm);
          setUsersFetched(true);
        }),
      );
    }
    return () => {
      dispatch({ type: GET_LESSON, payload: null });
    };
    // return () => {
    //   users = null;
    //     };
  }, [dispatch, filterOptions, debouncedSearchTerm, page, rowsPerPage, order, orderBy]);

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUserView = user => {
    console.log('i user 123', user);
    history.push(`/academy-cms/details/${user?._id}`);
    dispatch(setCurrentLesson(user));
    dispatch(getLessonDetails(user?.lessonID));
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
  };

  const handleUserEdit = user => {
    history.push(`/academy-cms/preview/${user?._id}`);
    // dispatch(setCurrentLesson(user));
    dispatch(getLessonDetails(user?.lessonID));
    // dispatch(setCurrentIssueManagement(user));
    // setOpenUserDialog(true);
  };

  const handleUserDelete = user => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    // dispatch(deleteUser(selectedUser._id, () => {
    //   dispatch(getIssueManagement(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
    // }));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <PageContainer heading="Academy CMS" breadcrumbs={breadcrumbs}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <UserTableToolbar
            selected={selected}
            setSelected={setSelected}
            onUserAdd={setOpenUserDialog}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setPage={setPage}
            setSearchedData={setSearchedData}
          />
          <TableContainer className={classes.container}>
            <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
              <UserTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={users?.total ?? 1}
              />

              <TableBody>
                {!!users?.lessons?.length ? (
                  users?.lessons.slice(0 * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <UserListRow
                      key={index}
                      row={row}
                      onRowClick={handleRowClick}
                      onUserEdit={handleUserEdit}
                      onUserDelete={handleUserDelete}
                      onUserView={handleUserView}
                      isSelected={isSelected}
                      getAcademyCMSLessonParams={{
                        orderBy,
                        order,
                        debouncedSearchTerm,
                        page,
                        rowsPerPage,
                        filterOptions,
                      }}
                    />
                  ))
                ) : (
                  <TableRow style={{ height: 53 * 6 }}>
                    <TableCell colSpan={7} rowSpan={10}>
                      {isFilterApplied ? (
                        <NoRecordFound>There are no records found.</NoRecordFound>
                      ) : (
                        <NoRecordFound>{usersFetched ? 'There are no records found.' : 'Loading data...'}</NoRecordFound>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={users?.totalRecords ?? 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>

        {openUserDialog && (
          <AddEditUser
            open={openUserDialog}
            onCloseDialog={handleCloseUserDialog}
            getSubAdminManagementParams={{ orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions }}
          />
        )}
        {openViewDialog && <UserDetailView open={openViewDialog} onCloseDialog={handleCloseViewDialog} />}

        <ConfirmDialog
          open={openConfirmDialog}
          // title={`Confirm delete ${selectedUser.name}`}
          title={`Delete Issue?`}
          content={'You will not be able to recover records.'}
          btnLabels={{
            cancel: 'Cancel',
            confirm: 'Delete',
          }}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      </div>
    </PageContainer>
  );
};

export default AcademyCMSModule;
