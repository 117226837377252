import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVirsionData, pushVirsionData, deleteVersion } from '../../../redux/actions/Setting';
import CmtCard from '../../../@coremat/CmtCard';
import CmtCardHeader from '../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '../../../@jumbo/constants/ErrorMessages';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import Typography from '@material-ui/core/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import { TextareaAutosize, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import clsx from 'clsx';
import Multiselect from 'multiselect-react-dropdown';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import SaveIcon from '@material-ui/icons/Save';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field, FieldArray, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { getEndUserList } from 'redux/actions/EndUserManagement';
import QuestionAccordion from '../../../@jumbo/components/Common/QuestionAccordion/index';
import LessonAccordion from '@jumbo/components/Common/LessonAccordion';
import './AcademyCMSDetails.css';
import {
  createNewLesson,
  getAcademyModuleList,
  getLessonDetails,
  getLessonList,
  updateAcademyLesson,
} from 'redux/actions/AcademyCMS';
import { useHistory } from 'react-router-dom';

const variant = 'default';

const redirect = [
  {
    name: 'Company AnalystRating Chart',
    _id: 2,
  },
  {
    name: 'ChatBot View',
    _id: 3,
  },
  {
    name: 'Company Rsi Price Chart',
    _id: 4,
  },
  {
    name: 'Company Profit Chart',
    _id: 5,
  },
];

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '& .MuiFormLabel-root': {
      color: '#909090',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.text.primary,
    },
    cardContentRoot: {
      padding: '0 !important',
    },

    scrollbarRoot: {
      height: 340,
    },
    badgeRoot: {
      color: theme.palette.common.white,
      borderRadius: 30,
      fontSize: 12,
      padding: '2px 10px',
      display: 'inline-block',
    },
    authThumb: {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '50%',
        order: 2,
      },
    },
    authContent: {
      padding: 30,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: props => (props.variant === 'default' ? '50%' : '100%'),
        order: 1,
      },
      [theme.breakpoints.up('xl')]: {
        padding: 50,
      },
    },
    titleVersion: {
      // marginBottom: 14,
      letterSpacing: 0.15,
      marginRight: 24,
      color: theme.palette.text.primary,
    },
    headerTitle: {
      fontSize: 20,
      fontStyle: 'bold',
    },
    textFieldRoot: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: alpha(theme.palette.common.dark, 0.12),
      },
    },
    formcontrolLabelRoot: {
      '& .MuiFormControlLabel-label': {
        [theme.breakpoints.down('xs')]: {
          fontSize: 12,
        },
      },
    },
    noRecord: {
      textAlign: 'center',
      height: '250px',
      fontSize: 16,
      color: theme.palette.text.disabled,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.up('lg')]: {
        fontSize: 20,
      },
      border: 'none',
    },
    tableCellRoot: {
      textAlign: 'center',
    },
  },
  tableHead: {
    '& .MuiTableCell-head': {
      fontWeight: 600,
    },
  },
  tableRowRoot: {
    '& .MuiTableCell-root': {
      fontWeight: 300,
    },
  },
  disabledText1: {
    fontSize: 16,
    fontStyle: 'italic',
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('lg')]: {
      fontSize: 12,
    },
  },
  disabledText2: {
    marginLeft: '10px',
    fontSize: 16,
    fontStyle: 'italic',
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('lg')]: {
      fontSize: 12,
    },
  },
  backButton: {
    marginBottom: '16px',
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const filter = createFilterOptions();

const LessonDetails = () => {
  const classes = useStyles({ variant });
  const { id } = useParams();
  const history = useHistory();
  const { currentUser } = useSelector(({ ACADEMYCMS }) => ACADEMYCMS);
  const [selectedValues, setSelectedValues] = useState([]);
  const initialFields = currentUser?.thingsToKnow?.map(item => ({
    title: item.title || '',
    description: item.description || '',
  })) || [{ title: '', description: '' }];
  const [fields, setFields] = useState(initialFields);
  const [points, setPoints] = useState(['']);
  const [selectedCorrectOption, setSelectedCorrectOption] = useState('');
  const [state, setState] = React.useState({ checkedB: true });
  const [moduleValue, setModuleValue] = React.useState(null);
  const [endUserList, setEndUserList] = useState([]);
  const [previewImage, setPreviewImage] = useState(currentUser && currentUser?.lessonImage);
  const [academyModuleList, setAcademyModuleList] = useState([]);
  const [lessonCount, setLessonCount] = useState(5);
  const dispatch = useDispatch();
  console.log('currentUser LessonList new :::: ', currentUser);
  console.log('academyModuleList', academyModuleList);

  const breadcrumbs = [
    { label: 'Home', link: '/' },
    { label: id ? 'Lesson Details' : 'Add New Lesson', isActive: true },
  ];

  useEffect(() => {
    const fetchData = () => {
      dispatch(
        getEndUserList('created_at', 'desc', '', 0, -1, '', 3, data => {
          console.log('response users', data?.data?.data);
          setEndUserList(data?.data?.data || []);
        }),
      );
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchDataForAcademyModule = () => {
      dispatch(
        getAcademyModuleList(data => {
          setAcademyModuleList(data || []);
          const selectedModule = data?.find(module => module._id === currentUser?.module_id);
          if (selectedModule) {
            setModuleValue(selectedModule);
          }
        }),
      );
    };
    fetchDataForAcademyModule();
  }, [dispatch, currentUser]);

  const handleBackClick = () => {
    history.goBack();
  };

  const validationSchema = Yup.object().shape({
    lessonTitle: Yup.string().required('Lesson title is required'),
  });

  const updateAcademyCMSFormHandler = async (values, actions) => {
    const formData = new FormData();
    formData.append('title', values.lessonTitle);
    console.log('addModule', values?.addModule);

    formData.append('moduleName', values.addModule);
    formData.append('introduction', values.lessonIntroduction);
    formData.append('subIntroduction', values.lessonSubIntroduction);
    formData.append('previewText', values.lessonPreviewText);
    formData.append('lesson_banner_image', values.lessonImage);
    formData.append('personalizeLesson', values.personalizeLesson);

    if (values.personalizeLesson) {
      formData.append('user_ids', JSON.stringify(values.selectedUsers));
      // formData.append('user_ids', values.selectedUsers);
      formData.append('isPersonalizeForAll', values.isPrivate);
    }

    const dynamicBulletData = values.bulletToAdd?.map(bullet => ({
      title: bullet.title,
      points: bullet.points,
    }));
    formData.append('bulletToAdd', JSON.stringify(dynamicBulletData));

    formData.append('thingsToKnow', JSON.stringify(values.fields));

    values.lessons.forEach((lesson, index) => {
      if (lesson.lessonImage) {
        formData.append(`lessonImage${index}`, lesson.lessonImage);
      }
      formData.append(
        `lessonData${index}`,
        JSON.stringify({
          header: lesson.header,
          subHeader: lesson.subHeader,
          paragraph: lesson.paragraph,
          subParagraph: lesson.subParagraph,
        }),
      );
    });
    console.log('quizContent Value', values?.questions);

    const quizContent = {
      title: values.quizTitle,
      questions: values.questions.map(question => ({
        question: question.question,
        options: question.options.map(option => option),
        correctOption: question.correctOption,
      })),
    };
    formData.append('quizContent', JSON.stringify(quizContent));

    if (values.reDirection) {
      formData.append('reDirection', values.reDirection?._id ? values?.reDirection?._id : values.reDirection);
    }

    formData.append('congratulationMessage', values?.congratulationMessage);

    dispatch(updateAcademyLesson(formData, id));
  };

  return (
    <>
      <PageContainer heading={id ? 'Lesson Details' : 'Add New Lesson'} breadcrumbs={breadcrumbs}>
        <Button className={classes.backButton} variant="outlined" onClick={handleBackClick}>
          Back
        </Button>
        <Formik
          enableReinitialize={true}
          initialValues={{
            lessonTitle: currentUser?.title || '',
            addModule: currentUser?.module || '',
            lessonIntroduction: currentUser?.introduction || '',
            lessonSubIntroduction: currentUser?.subIntroduction || '',
            lessonPreviewText: currentUser?.previewText || '',
            lessonImage: currentUser?.lessonImage || '',
            bulletToAdd: [
              {
                title: currentUser?.bulletToAdd[0]?.title || '',
                points: currentUser?.bulletToAdd[0]?.points || [''],
              },
            ],
            lessons: currentUser?.lessonContent?.map(lesson => ({
              header: lesson.header || '',
              subHeader: lesson.subHeader || '',
              paragraph: lesson.paragraph || '',
              subParagraph: lesson.subParagraph || '',
              lessonImage: lesson.imageURL || null,
            })),
            fields: fields,
            selectedUsers: currentUser?.user_ids || [],
            isPrivate: currentUser?.isPersonalizeForAll || false,
            personalizeLesson: currentUser?.personalizeLesson || false,
            congratulationMessage: currentUser?.congratulationMessage || '',
            reDirection: redirect.find(option => option._id === currentUser?.messageUniqueId) || null,
            quizTitle: currentUser?.quizContent[0]?.title || '',
            questions: currentUser?.quizContent[0]?.questions?.map(question => ({
              question: question.question || '',
              options: question.options.map(option => option || ''),
              correctOption: question.correctOption || '',
            })) || [
              {
                question: '',
                options: ['', '', '', ''],
                correctOption: '',
              },
            ],
          }}
          validationSchema={validationSchema}
          onSubmit={updateAcademyCMSFormHandler}
          // setSubmitting(false)
        >
          {({ errors, isSubmitting, values, setFieldValue }) => {
            console.log('values Form', values);
            return (
              <Form>
                <GridContainer>
                  <Grid item xs={12} lg={12} sm={12}>
                    <CmtCard alignItems="center">
                      <Box p={8} pb={0}>
                        <Grid container spacing={5}>
                          <Grid item xs={12}>
                            <Box alignItems="center">
                              <AppTextInput
                                id="lessonTitle"
                                name="lessonTitle"
                                size="medium"
                                label={'Lesson title'}
                                value={values?.lessonTitle}
                                onChange={e => setFieldValue('lessonTitle', e.target.value)}
                                // placeholder="Lesson title"
                                fullWidth
                                margin="0"
                                variant="outlined"
                              />
                              <ErrorMessage name="lessonTitle" component="div" className="error" />
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box alignItems="center">
                              <Autocomplete
                                value={moduleValue}
                                onChange={(event, newValue) => {
                                  if (typeof newValue === 'string') {
                                    setModuleValue({
                                      module_name: newValue,
                                    });
                                    setFieldValue('addModule', newValue);
                                  } else if (newValue && newValue.inputValue) {
                                    setModuleValue({
                                      module_name: newValue.inputValue,
                                    });
                                    setFieldValue('addModule', newValue.inputValue);
                                  } else {
                                    setModuleValue(newValue);
                                    setFieldValue('addModule', newValue?.module_name || '');
                                  }
                                }}
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);

                                  if (params.inputValue !== '') {
                                    filtered.push({
                                      inputValue: params.inputValue,
                                      module_name: `Add "${params.inputValue}"`,
                                    });
                                  }

                                  return filtered;
                                }}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                id="free-solo-with-text-demo"
                                options={academyModuleList}
                                getOptionLabel={option => {
                                  if (typeof option === 'string') {
                                    return option;
                                  }
                                  if (option.inputValue) {
                                    return option.inputValue;
                                  }
                                  return option.module_name;
                                }}
                                renderOption={option => (option.module_name ? option.module_name : option.title)}
                                style={{ width: 300 }}
                                freeSolo
                                renderInput={params => <TextField {...params} label="Add Module" variant="outlined" />}
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            <Box alignItems="center">
                              <TextareaAutosize
                                className="textAreaControl"
                                name="lessonIntroduction"
                                id="lessonIntroduction"
                                value={values?.lessonIntroduction}
                                onChange={e => setFieldValue('lessonIntroduction', e.target.value)}
                                minRows={5}
                                fullWidth
                                variant="outlined"
                                style={{
                                  width: '100%',
                                  padding: '14px',
                                  borderRadius: '4px',
                                  borderColor: 'rgba(0, 0, 0, 0.23)',
                                  fontSize: '14px',
                                  color: 'rgba(0, 0, 0, 0.87)',
                                }}
                                margin="0"
                                placeholder="Introduction"
                              />
                              <ErrorMessage name="lessonIntroduction" component="div" className="error" />
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box alignItems="center">
                              <TextareaAutosize
                                className="textAreaControl"
                                name="lessonSubIntroduction"
                                id="lessonSubIntroduction"
                                aria-label="minimum height"
                                value={values?.lessonSubIntroduction}
                                onChange={e => setFieldValue('lessonSubIntroduction', e.target.value)}
                                minRows={5}
                                fullWidth
                                variant="outlined"
                                style={{
                                  width: '100%',
                                  padding: '14px',
                                  borderRadius: '4px',
                                  borderColor: 'rgba(0, 0, 0, 0.23)',
                                  fontSize: '14px',
                                  color: 'rgba(0, 0, 0, 0.87)',
                                }}
                                margin="0"
                                placeholder="Sub introduction"
                              />
                              <ErrorMessage name="lessonSubIntroduction" component="div" className="error" />
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box alignItems="center">
                              <TextareaAutosize
                                className="textAreaControl"
                                name="lessonPreviewText"
                                id="lessonPreviewText"
                                value={values?.lessonPreviewText}
                                onChange={e => setFieldValue('lessonPreviewText', e.target.value)}
                                aria-label="minimum height"
                                minRows={5}
                                style={{
                                  width: '100%',
                                  padding: '14px',
                                  borderRadius: '4px',
                                  borderColor: 'rgba(0, 0, 0, 0.23)',
                                  fontSize: '14px',
                                  color: 'rgba(0, 0, 0, 0.87)',
                                }}
                                placeholder="Preview Text"
                              />
                              <ErrorMessage name="lessonPreviewText" component="div" className="error" />
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box alignItems="center">
                              <Typography
                                style={{
                                  marginBottom: '10px',
                                  marginBottom: '15px',
                                  fontSize: '20px',
                                  fontWeight: '600',
                                }}>
                                Lesson Image
                              </Typography>
                              <div className={classes.root}>
                                <input
                                  accept="image/*"
                                  className={classes.input}
                                  id="lessonImage"
                                  name="lessonImage"
                                  type="file"
                                  onChange={e => {
                                    const files = e.target.files;
                                    if (files && files?.length > 0) {
                                      setFieldValue('lessonImage', files[0]);
                                      const previewUrl = URL.createObjectURL(files[0]);
                                      setPreviewImage(previewUrl);
                                    }
                                  }}
                                />

                                <label htmlFor="lessonImage">
                                  <Button variant="contained" color="primary" component="span">
                                    <IconButton style={{ color: 'white' }} aria-label="upload picture" component="span">
                                      <PhotoCamera />
                                    </IconButton>{' '}
                                    Lesson image
                                  </Button>
                                </label>

                                {previewImage && (
                                  <div>
                                    <div
                                      className="relative border-1 rounded-lg overflow-hidden"
                                      style={{
                                        position: 'relative',
                                        display: 'inline-flex',
                                        width: 'auto',
                                        borderRadius: '8px',
                                        overflow: 'hidden',
                                      }}>
                                      <img
                                        src={previewImage}
                                        alt="preview-lesson-image"
                                        className="preview-img"
                                        style={{
                                          objectFit: 'cover',
                                          height: 'auto',
                                          width: '100%',
                                          maxWidth: '250px',
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="absolute top-0 right-0 mt-[-10px] mr-[-10px] w-[24px] h-[24px] bg-white border border-gray-200 rounded-full flex items-center justify-center p-1"
                                        style={{
                                          marginTop: '5px',
                                          marginRight: '5px',
                                          position: 'absolute',
                                          top: '5px',
                                          right: '5px',
                                          background: '#ffffff',
                                          borderRadius: '9999px',
                                          padding: '2px',
                                          zIndex: '2',
                                          width: '24px',
                                          height: '24px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          setFieldValue('lessonImage', null);
                                          setPreviewImage(null);
                                        }}>
                                        <img
                                          src={`${process.env.PUBLIC_URL}/images/icons/close.svg`}
                                          className="text-gray-500 w-4 h-4"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <FieldArray name="lessons">
                        {({ remove, push }) => (
                          <>
                            {values.lessons?.map((lesson, index) => (
                              <LessonAccordion
                                key={index}
                                lessonNumber={index + 1}
                                headerName={`lessons[${index}].header`}
                                subHeaderName={`lessons[${index}].subHeader`}
                                paragraphName={`lessons[${index}].paragraph`}
                                subParagraphName={`lessons[${index}].subParagraph`}
                                imageName={`lessons[${index}].lessonImage`}
                                setFieldValue={setFieldValue}
                                lessonContent={lesson}
                              />
                            ))}
                            <Grid item xs={12} display="flex" alignItems="center" justifyContent="flex-end">
                              <Box mt={5} display="flex" alignItems="center" justifyContent="flex-end">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    push({ header: '', subHeader: '', paragraph: '', subParagraph: '', lessonImage: null })
                                  }>
                                  Add New Content
                                </Button>
                                {values.lessons?.length > 1 && (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ marginLeft: '10px', marginRight: '10px' }}
                                    onClick={() => remove(values.lessons?.length - 1)}>
                                    Remove Last Content
                                  </Button>
                                )}
                              </Box>
                            </Grid>
                          </>
                        )}
                      </FieldArray>
                      <Box p={8} pt={2}>
                        <Typography
                          style={{ marginBottom: '10px', marginBottom: '15px', fontSize: '20px', fontWeight: '600' }}>
                          Quiz
                        </Typography>

                        <div className={classes.root}>
                          <Box alignItems="center" style={{ marginBottom: '15px' }}>
                            <AppTextInput
                              id="quizTitle"
                              name="quizTitle"
                              size="medium"
                              label="Quiz title"
                              value={values?.quizTitle && values?.quizTitle}
                              onChange={e => setFieldValue('quizTitle', e.target.value)}
                              placeholder="Quiz title"
                              fullWidth
                              margin="0"
                              variant="outlined"
                            />
                            <ErrorMessage name="quizTitle" component="div" className="error" />
                          </Box>
                          {values.questions.map((question, index) => (
                            <QuestionAccordion
                              key={index}
                              question={question}
                              options={question.options}
                              correctAnswer={selectedCorrectOption}
                              correctOption={question.correctOption}
                              index={index}
                              setFieldValue={setFieldValue}
                              setSelectedCorrectOption={correctOption =>
                                setFieldValue(`questions.${index}.correctOption`, correctOption)
                              }
                            />
                          ))}
                        </div>
                      </Box>
                      <Box p={8} pt={2}>
                        <Typography
                          style={{ marginBottom: '10px', marginBottom: '15px', fontSize: '20px', fontWeight: '600' }}>
                          Bullet to add
                        </Typography>

                        <Grid container spacing={5}>
                          <Grid item xs={12}>
                            <AppTextInput
                              id="bulletToAddTitle"
                              name="bulletToAdd[0].Title"
                              label="Bullet to add title"
                              placeholder="Bullet to add title"
                              value={values?.bulletToAdd[0]?.title}
                              onChange={e => setFieldValue(`bulletToAdd[0].title`, e.target.value)}
                              size="medium"
                              fullWidth
                              margin="0"
                              variant="outlined"
                            />
                            {/* <ErrorMessage name={`bulletToAdd[0].title`} component="div" className="error" /> */}
                          </Grid>

                          <Grid item xs={12}>
                            <FieldArray name="bulletToAdd[0].points">
                              {({ push, remove }) => (
                                <div>
                                  {values?.bulletToAdd?.map((bullet, pointIndex) =>
                                    bullet.points?.map((point, pointIndex) => (
                                      <Box key={pointIndex} display="flex" alignItems="center">
                                        <AppTextInput
                                          id={`bulletToAdd[0].points[${pointIndex}]`}
                                          name={`bulletToAdd[0].points[${pointIndex}]`}
                                          placeholder={`Point ${pointIndex + 1}`}
                                          label="Points"
                                          value={point}
                                          onChange={e =>
                                            setFieldValue(`bulletToAdd[0].points[${pointIndex}]`, e.target.value)
                                          }
                                          size="medium"
                                          fullWidth
                                          margin="0"
                                          style={{ marginTop: '10px' }}
                                          variant="outlined"
                                        />
                                        <IconButton
                                          onClick={() => {
                                            remove(pointIndex);
                                          }}
                                          color="primary"
                                          style={{ background: 'rgba(1, 140, 147, 0.04)', marginRight: '10px' }}>
                                          <RemoveIcon style={{ border: '2px' }} />
                                        </IconButton>
                                      </Box>
                                    )),
                                  )}
                                  <IconButton
                                    onClick={() => {
                                      push('');
                                    }}
                                    color="primary"
                                    style={{ background: 'rgba(1, 140, 147, 0.04)', marginTop: '10px' }}>
                                    <AddIcon />
                                  </IconButton>
                                </div>
                              )}
                            </FieldArray>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box p={8} pt={2}>
                        <Typography
                          style={{ marginBottom: '10px', marginBottom: '15px', fontSize: '20px', fontWeight: '600' }}>
                          Is Personalize Lesson :
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values?.personalizeLesson}
                              onChange={e => setFieldValue('personalizeLesson', e.target.checked)}
                              name="personalizeLesson"
                              id="personalizeLesson"
                              color="primary"
                            />
                          }
                          label="Is Personalize"
                        />
                      </Box>
                      {values?.personalizeLesson && (
                        <Box p={8} pt={2}>
                          <Typography
                            style={{ marginBottom: '10px', marginBottom: '15px', fontSize: '20px', fontWeight: '600' }}>
                            Select user(s) to Personalize(This will not change the access rights to this lesson)
                          </Typography>
                          <Multiselect
                            options={endUserList
                              ?.filter(item => item?.first_name)
                              ?.map(item => ({
                                key: item?._id,
                                // cat: item?.first_name,
                                cat: `${item?.first_name} (${item?.email})`,
                              }))}
                            selectedValues={values.selectedUsers
                              ?.map(userId => {
                                const user = endUserList.find(item => item._id === userId);
                                return user && user?.first_name
                                  ? {
                                      key: user?._id,
                                      // cat: user?.first_name,
                                      cat: `${user?.first_name} (${user?.email})`,
                                    }
                                  : null;
                              })
                              .filter(val => val !== null)}
                            onSelect={(selectedList, selectedItem) => {
                              const selectedKeys = selectedList?.map((item, key) => item.key);
                              setFieldValue('selectedUsers', selectedKeys);
                            }}
                            onRemove={(selectedList, removedItem) => {
                              const selectedKeys = selectedList?.map(item => item.key);
                              setSelectedValues(selectedList);
                              // onRemove(selectedList, removedItem);
                              setFieldValue('selectedUsers', selectedKeys);
                            }}
                            displayValue="cat"
                            showCheckbox={true}
                            placeholder="Select Users"
                            style={{
                              chips: {
                                background: '#64748B',
                                border: '1px solid #64748B',
                                color: '#ffffff',
                              },
                              multiselectContainer: {
                                color: '#64748B',
                              },
                              searchBox: {
                                // border: "1px solid #64748B",
                                innerWidth: '100%',
                                padding: 10,
                              },
                              inputField: {
                                margin: '0',
                                paddingLeft: '5px',
                              },
                              optionContainer: {
                                border: '1px solid #64748B',
                              },
                            }}
                          />
                        </Box>
                      )}
                      {values?.personalizeLesson && (
                        <Box p={8} pt={2}>
                          <Typography
                            style={{ marginBottom: '10px', marginBottom: '15px', fontSize: '20px', fontWeight: '600' }}>
                            Is private(This will make this lesson accessible to the above selected users only) :
                            {/* Is public (This will make this lesson accessible to all users) : */}
                          </Typography>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values?.isPrivate}
                                onChange={e => setFieldValue('isPrivate', e.target.checked)}
                                name="isPrivate"
                                id="isPrivate"
                                color="primary"
                              />
                            }
                            label="Is Private"
                          />
                        </Box>
                      )}

                      <Box p={8} pt={2}>
                        <Typography
                          style={{ marginBottom: '10px', marginBottom: '15px', fontSize: '20px', fontWeight: '600' }}>
                          Things to know
                        </Typography>
                        <Box p={0} pb={0} pt={0} style={{ width: '100%' }}>
                          <Box alignItems="center" mb={0}>
                            <Grid container spacing={5}>
                              <Grid item xs={12}></Grid>
                            </Grid>

                            {values?.fields?.map((field, index) => (
                              <Grid container spacing={5} key={index}>
                                <Grid item xs={12}>
                                  <Field
                                    name={`fields[${index}].title`}
                                    placeholder="Thing To Know Title"
                                    render={({ field }) => (
                                      <AppTextInput
                                        {...field}
                                        // id={`fields[${index}].title`}
                                        // name={`fields[${index}].title`}
                                        label="Title"
                                        placeholder="Thing To Know Title"
                                        fullWidth
                                        margin="0"
                                        variant="outlined"
                                        size="medium"
                                        // value={field.title}
                                        onChange={e => {
                                          setFieldValue(`fields[${index}].title`, e.target.value);
                                        }}
                                      />
                                    )}
                                  />
                                  <ErrorMessage name={`fields[${index}].title`} component="div" className="error" />
                                </Grid>
                                <Grid item xs={12}>
                                  <Field
                                    name={`fields[${index}].description`}
                                    render={({ field }) => (
                                      <TextareaAutosize
                                        {...field}
                                        className="textAreaControl"
                                        aria-label="minimum height"
                                        minRows={5}
                                        style={{
                                          width: '100%',
                                          padding: '14px',
                                          borderRadius: '4px',
                                          borderColor: 'rgba(0, 0, 0, 0.23)',
                                          fontSize: '14px',
                                          color: 'rgba(0, 0, 0, 0.87)',
                                        }}
                                        // name={`fields[${index}].description`}
                                        placeholder="Description"
                                        // value={field.description}
                                        // onChange={e => {
                                        //   const newFields = [...fields];
                                        //   newFields[index].description = e.target.value;
                                        //   setFields(newFields);
                                        // }}
                                        // onChange={e => setFieldValue(`fields[${index}].description`, e.target.value)}
                                      />
                                    )}
                                  />
                                  <ErrorMessage name={`fields[${index}].description`} component="div" className="error" />
                                </Grid>
                              </Grid>
                            ))}

                            <Grid item xs={12} display="flex" alignItems="center" justifyContent="flex-end">
                              <Box mt={3} display="flex" alignItems="center" justifyContent="flex-end">
                                <IconButton
                                  onClick={() => {
                                    if (values.fields?.length > 1) {
                                      setFields(fields.slice(0, -1));
                                      // setFieldValue('fields', values.fields.slice(0, -1));
                                    }
                                  }}
                                  color="primary"
                                  style={{ background: 'rgba(1, 140, 147, 0.04)', marginRight: '10px' }}>
                                  <RemoveIcon style={{ border: '2px' }} />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    setFields([...fields, { title: '', description: '' }]);
                                    // setFieldValue('fields', [...values.fields, { title: '', description: '' }]);
                                  }}
                                  color="primary"
                                  style={{ background: 'rgba(1, 140, 147, 0.04)' }}>
                                  <AddIcon style={{ border: '2px' }} />
                                </IconButton>
                              </Box>
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                      <Box p={8} pt={2}>
                        <TextareaAutosize
                          className="textAreaControl"
                          aria-label="minimum height"
                          name="congratulationMessage"
                          id="congratulationMessage"
                          value={values?.congratulationMessage}
                          onChange={e => setFieldValue('congratulationMessage', e.target.value)}
                          minRows={5}
                          style={{
                            width: '100%',
                            padding: '14px',
                            borderRadius: '4px',
                            borderColor: 'rgba(0, 0, 0, 0.23)',
                            fontSize: '14px',
                            color: 'rgba(0, 0, 0, 0.87)',
                          }}
                          placeholder="Congratulation message"
                        />
                        <ErrorMessage name="congratulationMessage" component="div" className="error" />
                      </Box>
                      <Box p={8} pt={2}>
                        <Autocomplete
                          id="reDirection"
                          name="reDirection"
                          style={{ width: 300 }}
                          options={redirect}
                          classes={{
                            option: classes.option,
                          }}
                          value={values.reDirection}
                          onChange={(event, value) => {
                            setFieldValue('reDirection', value || null);
                          }}
                          autoHighlight
                          getOptionLabel={option => option.name || ''}
                          renderOption={option => <React.Fragment>{option.name}</React.Fragment>}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Choose a re-direction"
                              variant="outlined"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                              }}
                            />
                          )}
                        />
                      </Box>
                      <Box p={8}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          // size="large"
                          className={classes.button}
                          startIcon={<SaveIcon />}>
                          Save
                        </Button>
                      </Box>
                    </CmtCard>
                  </Grid>
                </GridContainer>
              </Form>
            );
          }}
        </Formik>
      </PageContainer>
    </>
  );
};

export default LessonDetails;
